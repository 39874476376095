import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { VideoService } from 'src/app/@domain/video/application/video.service';
import { Video } from '../../../../../@domain/video/entities/video.interface';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { VideoItemComponent } from '../../video-item/video-item.component';

@Component({
    selector: 'app-latest-lectures',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ VideoItemComponent, SpinnerComponent, AsyncPipe, CardGridComponent ],
    styles: `
        :host {
            display: block;
            width: 100%;
            min-height: 300px;
        }
    `,
    template: `
        @if (lectures$ | async; as lectures) {
            <app-card-grid [listView]="false">
                @for (lecture of lectures; track lecture) {
                    <app-video-item [lecture]="lecture" priorityImageLoading/>
                }
            </app-card-grid>
        } @else {
            <app-spinner/>
        }
    `
})
export class LatestVideosComponent {
    lectures$: Observable<Video[]>

    constructor(videoService: VideoService) {
        this.lectures$ = videoService.getLatestVideos().pipe(
            map(({ results }) => results ?? [])
        );
    }

}
