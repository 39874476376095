import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { ActivatedRoute, Router, RouterLink, RouterOutlet } from '@angular/router';
import { UserService } from '../../../@domain/user/application/user.service';
import { ProfileHeaderComponent } from '../../../@ui/content/profile/profile-header/profile-header.component';
import { DropdownComponent } from '../../../@ui/global/sort/dropdown.component';
import { AppRoutes } from '../../../routing/types/app-route';

@Component({
    selector: 'app-profile-dashboard',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ RouterLink, DropdownComponent, RouterOutlet, AsyncPipe, ProfileHeaderComponent ],
    styles: `
        @use "../../../../styles/functions" as *;
        @use "../../../../styles/variables" as *;

        :host {
            width: 100%;
            flex: 1;

            @include break(500px) {

                .tabs-container {
                    gap: 0.5rem;
                }

                .tabs {
                    justify-content: space-evenly;
                    display: table;
                    width: 100%;

                    a {
                        display: table-cell;
                        text-align: center;
                        min-width: unset;
                        font-size: 0.9rem;
                        font-weight: 500;
                        padding-bottom: 12px;
                    }
                }
            }
        }

        .container {
            display: flex;
            flex-direction: column;
            flex: 1
        }

        .btn-edit {
            z-index: 2;
            position: relative;
            color: $black;
            border: solid 1px $grey;
            background-color: $white;
            height: fit-content;
            align-self: flex-end;
        }

        .tabs-container {
            display: flex;
            flex-direction: column;
            padding-top: 1rem;
        }

        .tabs {
            display: flex;
            border-bottom: 2px solid #eee;
            padding-top: 0.5rem;
            margin-bottom: 2rem;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 115px;
                padding-bottom: 15px;
                text-decoration: none;
                transform: translateY(1px);
                color: black;
                border-bottom: 3px solid transparent;

                &.active {
                    border-color: $red;
                }
            }
        }
    `,
    template: `
        @if (user(); as profile) {
            <app-profile-header [profile]="profile"/>
        }

        <section class="container">
            <div class="tabs-container">
                <a class="btn btn-edit rounded-full" routerLink="/{{ AppRoutes.Profile }}/edit">Edit profile</a>
                <div class="tabs">
                    @for (item of navItems(); track item) {
                        <a [class.active]="item.name === currentTab" [routerLink]="item.value">
                            {{ item.name }}
                        </a>
                    }
                </div>
            </div>

            <router-outlet/>
        </section>
    `
})
export default class ProfilePage {
    private userService = inject(UserService);
    private route = inject(ActivatedRoute);
    private router = inject(Router);

    public user = this.userService.userProfile;
    public AppRoutes = AppRoutes;
    public navItems = computed(() => {
        const userItems = [
            { name: 'Playlists', value: 'playlists' },
            { name: 'Bookmarks', value: 'bookmarks' },
            { name: 'Following', value: 'following' },
        ]

        return this.userService.isAdmin()
            ? [
                { name: 'Videos', value: 'videos' },
                ...userItems,
            ]
            : userItems;
    });

    public get currentTab(): string {
        const tab = this.navItems().find(i => i.value === this.route.firstChild!.routeConfig!.path!)?.name;

        return tab ?? '';
    }

}
