import { AsyncPipe, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EventService } from 'src/app/@domain/event/application/event.service';
import { Event } from '../../../../../@domain/event/entities/event.interface';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';
import { CardGridComponent } from '../../../card-grid/card-grid.component';
import { EventItemComponent } from '../../events/event-item/event-item.component';

@Component({
    selector: 'app-events-section',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ EventItemComponent, SpinnerComponent, AsyncPipe, SlicePipe, CardGridComponent ],
    styles: `
        @import "../../../../../../styles/functions";

        :host {
            display: block;
            width: 100%;
            min-height: 300px;
        }
    `,
    template: `
        @if (events$ | async; as events) {
            <app-card-grid [listView]="false" [maxCards]="3">
                @for (event of events | slice: 0:3; track event) {
                    <app-event-item [event]="event"/>
                }
            </app-card-grid>
        } @else {
            <app-spinner/>
        }
    `
})
export class EventsSectionComponent {
    events$: Observable<Event[]>

    constructor(private eventsService: EventService) {
        this.events$ = this.eventsService.getLatestEvents()
            .pipe(
                map(({ results }) => results ?? [])
            )
    }

}
