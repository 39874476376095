import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { map, Observable } from 'rxjs';
import { CategoryService } from 'src/app/@domain/category/application/category.service';
import { SwiperOptions } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { ImageDirective } from '../../../../../@common/DOM/image.directive';
import { Category } from '../../../../../@domain/category/entities/category.interface';
import { AppRoutes } from '../../../../../routing/types/app-route';
import { SpinnerComponent } from '../../../../global/spinner/spinner.component';

@Component({
    selector: 'app-categories',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        SwiperModule,
        RouterLink,
        ImageDirective,
        FaIconComponent,
        SpinnerComponent,
        AsyncPipe
    ],
    styles: `
        @import "../../../../../../styles/variables";

        :host {
            display: block;
            width: 100%;
            min-height: 175px;
            --slide-size: 150px;

            @media screen and (max-width: 900px) {
                --slide-size: 120px;
            }

            @media screen and (max-width: 600px) {
                min-height: 135px;
                --slide-size: 100px;
            }

            .slide {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-decoration: none;
                color: $black;
                transition: 0.05s;

                @media screen and (min-width: 600px) {
                    &:hover {
                        font-weight: bold;

                        img {
                            transform: scale(1.1);
                            transition: 0.6s;
                        }
                    }
                }
            }

            img {
                max-height: var(--slide-size);
                height: var(--slide-size);
                transform: scale(1);
                transition: 0.3s;
                aspect-ratio: 1;
            }
        }

        .swiper-button-next,
        .swiper-button-prev {
            position: absolute;
            height: fit-content;
            z-index: 1000;
            cursor: pointer;
            font-size: 2rem;
            color: #6b7280;
            top: 50%;

            &:hover {
                color: $black;
            }
        }

        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    `,
    template: `
        @if (categories$ | async; as categories) {
            <div class="swiper">
                <swiper [config]="config" #swiper>
                    @for (category of categories; track category.id) {
                        <ng-template swiperSlide>
                            <a class="slide" [title]="category.name" [routerLink]="[AppRoutes.Categories, category.id]"
                               [queryParams]="{ sort: 'published' }">
                                <img [src]="category.image" alt="{{ category.name }} image" loading="lazy"/>
                                <p class="text-center">{{ category.name }}</p>
                            </a>
                        </ng-template>
                    }
                </swiper>
                <fa-icon class="swiper-button-prev" [icon]="faArrowLeft" (click)="slidePrev()"/>
                <fa-icon class="swiper-button-next" [icon]="faArrowRight" (click)="slideNext()"/>
            </div>
        } @else {
            <app-spinner/>
        }
    `
})
export class CategoriesComponent {
    @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
    categories$?: Observable<Category[]>;
    AppRoutes = AppRoutes;
    faArrowRight = faArrowRight;
    faArrowLeft = faArrowLeft;

    constructor(private categoryService: CategoryService) {
        this.categories$ = this.categoryService.getTopCategories()
            .pipe(
                map(categories => categories.filter(category => !!category.image)),
            );
    }

    config: SwiperOptions = {
        slidesPerView: 1,
        spaceBetween: 45,
        lazy: true,
        breakpoints: {
            320: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            450: {
                slidesPerView: 4,
                spaceBetween: 10,
            },
            600: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
            720: {
                slidesPerView: 6,
                spaceBetween: 20,
            },
            900: {
                slidesPerView: 7,
                spaceBetween: 20,
            },
            1120: {
                slidesPerView: 8,
                spaceBetween: 20,
            },
        },
    };

    slideNext() {
        this.swiper?.swiperRef.slideNext(100);
    }

    slidePrev() {
        this.swiper?.swiperRef.slidePrev(100);
    }

}
